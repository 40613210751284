<!--  -->
<template>
	<div class="com-material">
		<div class="com-content">
			<div class="nav-wrap flex-ac">
				<img src="../../assets/image/icon-down.png" alt="" class="icon" />
				<span>资料下载</span>
			</div>
			<div class="material-list" v-if="materialList.length > 0">
				<div class="item flex-ac" v-for="(item, index) in materialList" :key="index">
					<div class="title flex1">
						<span>{{ index + 1 }}.{{ item.mname }}</span>
						<span v-if="item.createtime">({{ formatTime(item.createtime) }})</span>
					</div>
					<a target="_blank" :download="item.title" v-if="item.kcfile" :href="item.kcfile"><div class="btn">下载</div></a>
				</div>
			</div>
			<div class="no-material flex-jc" style="padding: 150px 0;" v-else>
				暂无相关资料
			</div>
		</div>
	</div>
</template>

<script>
import { $getMaterialList } from "../../services/course";
export default {
	name: "material",
	data() {
		return {
			courseId: "",
			materialList: [],
		};
	},
	components: {},

	computed: {},

	created() {
		this.courseId = this.$route.query.id;
		// this.courseId = '12892565407256576'
		this.getMaterialList();
	},

	methods: {
		async getMaterialList() {
			const { data } = await $getMaterialList({
				kcid: this.courseId,
			});
			this.materialList = data;
		},
		formatTime(t) {
			var time = new Date(t*1000);
			var y = time.getFullYear();
			var m = ('0'+(time.getMonth() + 1)).slice(-2);
			var d = ('0'+time.getDate()).slice(-2);
			return `${y}-${m}-${d}`
		},
	},
};
</script>

<style scoped lang="less">
.com-material {
	padding-top: 68px;
	.com-content {
		width: 1200px;
		margin: 0 auto;
		min-height: 500px;
		.nav-wrap {
			height: 87px;
			padding-top: 20px;
			border-bottom: 1px solid #eeefef;
			.icon {
				width: 22px;
				margin-right: 20px;
			}
			span {
				font-size: 18px;
				color: #1c1f21;
				font-weight: bold;
			}
		}
		.material-list {
			padding-top: 25px;
			.item {
				margin: 25px 0;
				font-size: 16px;
				color: #8c9094;
			}
			.btn {
				width: 66px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				font-size: 12px;
				color: #ffffff;
				background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				border-radius: 12px;
				user-select: none;
				cursor: pointer;
			}
		}
	}
}
</style>
