<template>
	<div class="course-detail-page">
		<div class="nav-container">
			<div class="course-container flex-jb">
				<div class="title-wrap flex">
					<div class="title textline-2" v-if="classInfo.info">{{ classInfo.info.kcname }}</div>
					<div class="collect flex-ac" @click="changeCollect">
						<img v-show="classInfo.usersctype == 1" src="../assets/image/icon-collect1.png" alt="" />
						<img v-show="classInfo.usersctype == 2" src="../assets/image/icon-collect.png" alt="" />
						收藏
					</div>
				</div>
				<div class="progress">
					<div class="btn" @click="enterCourse">{{ studyStatus }}</div>
					<!-- <div class="btn" v-if="!hasLogin" >报名学习</div>
					<div class="btn" v-else>{{ classInfo.userxxtype == 1 ? "继续学习" : "学习已完成" }}</div> -->
					<div class="step-container flex-jb-ac" v-if="hasLogin">
						<span>已学习</span>
						<div class="step-wrap">
							<div class="step" :style="{ width: classInfo.userxxjd + '%' }"></div>
						</div>
						<span>{{ classInfo.userxxjd }}%</span>
					</div>
				</div>
				<div class="nav-bar-container flex">
					<div class="nav-bar flex-jc-ac" @click="toggerNav(1)" :class="{ active: navBarActive == 1 }">
						<img v-show="navBarActive == 1" class="icon" src="../assets/image/icon-nav1.png" alt="" />
						<span>课程</span>
					</div>
					<div class="nav-bar flex-jc-ac" @click="toggerNav(2)" :class="{ active: navBarActive == 2 }">
						<img v-show="navBarActive == 2" class="icon" src="../assets/image/icon-nav2.png" alt="" />
						<span>资料</span>
					</div>
					<!-- <div class="nav-bar flex-jc-ac" @click="toggerNav(3)" :class="{ active: navBarActive == 3 }">
						<img v-show="navBarActive == 3" class="icon" src="../assets/image/icon-nav3.png" alt="" />
						<span>学员</span>
					</div> -->
				</div>
			</div>
		</div>
		<curriculum :classInfo="classInfo.info" :classList="classInfo.zjlist" v-if="navBarActive == 1" />
		<material v-else-if="navBarActive == 2" />
		<studyGroup ref="studyGroupRef" v-else-if="navBarActive == 3" />
	</div>
</template>

<script>
import { $getClassInfo, $changeClassCollectStatus, $enterCourse } from "../services/course";
import curriculum from "../components/course/curriculum.vue";
import material from "../components/course/material.vue";
import studyGroup from "../components/course/studyGroup.vue";
export default {
	name: "courseDetail",
	data() {
		return {
			navBarActive: 1, // 1课程 2资料 3学生
			courseId: "",
			classInfo: {},
		};
	},
	components: {
		curriculum,
		material,
		studyGroup,
	},
	created() {
		this.courseId = this.$route.query.id;
		// this.courseId = '1'
		this.navBarActive = this.$route.query.nav || 1;
		this.getClassInfo();
	},
	computed: {
		hasLogin() {
			if (this.$store.getters.useInfo && this.$store.getters.useInfo._token) {
				return true;
			} else {
				return false;
			}
		},
		studyStatus() {
			let str = "报名学习";
			if (this.classInfo?.userxxtype == 1) {
				str = "继续学习";
			} else if (this.classInfo?.userxxtype == 2) {
				str = "已完成";
			} else if (this.classInfo?.userxxtype == 3) {
				str = "报名学习";
			} else if (this.classInfo?.userxxtype == 4) {
				str = "报名人数已达上限";
			} else if (this.classInfo?.userxxtype == 5) {
				str = "报名暂未开放";
			}
			return str;
		},
	},
	methods: {
		toggerNav(nav) {
			if ((nav == 2 || nav == 3) && !this.hasLogin) {
				this.$confirm("您还未登录，需要现在去登录吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.$router.push({ path: "/login" });
					})
					.catch(() => {});
				return;
			}
			if (nav == 3 && [3, 4, 5].includes(this.classInfo.userxxtype)) {
				this.$message.error("您还未报名，不能查看学生信息");
				return;
			}
			this.navBarActive = nav;
		},
		async getClassInfo() {
			const { data } = await $getClassInfo({ kcid: this.courseId });
			this.classInfo = data;
		},
		async changeCollect() {
			if (!this.hasLogin) {
				this.$confirm("您还未登录，需要现在去登录吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.$router.push({ path: "/login" });
					})
					.catch(() => {});
				return;
			}
			const sctype = this.classInfo.usersctype == 1 ? 2 : 1;
			const { data } = await $changeClassCollectStatus({ kcid: this.courseId, sctype });
			if (data) {
				this.$set(this.classInfo, "usersctype", data);
			}
		},
		async enterCourse() {
			if (!this.hasLogin) {
				this.$confirm("您还未登录，需要现在去登录吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.$router.push({ path: "/login" });
					})
					.catch(() => {});
				return;
			}
			if (this.classInfo.userxxtype == 3) {
				try {
					const data = await $enterCourse({
						kcid: this.courseId,
					});
					this.$message.success("报名成功");
					this.getClassInfo();
					console.log(this.navBarActive);
					if (this.navBarActive == 3 && this.$refs.studyGroupRef) {
						this.$refs.studyGroupRef.getGroupList();
					}
				} catch (error) {
					this.$message.error("报名失败");
				}
			}
		},
	},
};
</script>
<style lang="less" scoped>
.nav-container {
	width: 100%;
	height: 280px;
	background-image: url("../assets/image/nav-bg.png");
	background-repeat: no-repeat;
	background-size: 100% 280px;
	.course-container {
		width: 1200px;
		height: 280px;
		margin: 0 auto;
		position: relative;
		.title-wrap {
			padding-top: 73px;
			font-size: 34px;
			color: #ffffff;
			.title {
				max-width: 15em;
			}
			.collect {
				align-items: flex-start;
				margin-left: 60px;
				font-size: 16px;
				color: rgba(255, 255, 255, 0.5);
				margin-top: 11px;
				line-height: 24px;
				cursor: pointer;
				img {
					width: 24px;
					margin-right: 13px;
				}
			}
		}
		.progress {
			padding-top: 84px;
			font-size: 14px;
			color: #ffffff;
			.btn {
				width: 280px;
				height: 52px;
				line-height: 52px;
				cursor: pointer;
				user-select: none;
				font-size: 18px;
				background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				border-radius: 26px;
				text-align: center;
				margin-bottom: 30px;
			}
			.step-container {
				.step-wrap {
					width: 180px;
					height: 12px;
					background-color: rgba(255, 255, 255, 0.5);
					border-radius: 6px;
					.step {
						height: 12px;
						background-color: #fff;
						border-radius: 6px;
					}
				}
			}
		}
		.nav-bar-container {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 52px;
			width: 540px;
			.nav-bar {
				width: 180px;
				color: #fff;
				user-select: none;
				cursor: pointer;
				font-size: 18px;
				.icon {
					width: 22px;
					margin-right: 15px;
				}
				&.active {
					background-color: #fff;
					border-radius: 10px 10px 0 0;

					color: #1c1f21;
				}
			}
		}
	}
}
</style>
