<!-- 课程 -->
<template>
	<div class="com-studyGroup">
		<div class="com-content">
			<div class="com-title" v-if="groupList.length > 0">分组列表</div>
			<div class="group-list-wrap" v-for="item in groupList" :key="item.zuid">
				<div class="group-title flex-ac">
					<div class="title">{{ item.zuname }}</div>
					<div class="count" v-if="item.zunum < 0">(小组暂未开放)</div>
					<div class="count" v-else-if="item.zunum > 0">
						<span class="now-count">{{ item.jrusernum }}</span>
						<span>/{{ item.zunum }}</span>
					</div>
				</div>
				<div class="group-list flex-wrap">
					<div class="item flex-jc-ac" :class="{ check: i.sid == userInfo.id }" v-for="(i, index) in item.userlist" :key="index">
						<div class="tag" v-if="i.jstype == 2">组长</div>
						<div class="name">{{ i.uname }}</div>
					</div>
				</div>
			</div>
			<div class="group-list-wrap" v-if="studentList.length > 0">
				<div class="group-title flex-ac">
					<div class="title">学生</div>
				</div>
				<div class="group-list flex-wrap">
					<div class="item flex-jc-ac join" @click="handleJoinGroup" v-if="power == 1">
						<div class="name">加入小组</div>
					</div>
					<div
						class="item flex-jc-ac hover"
						:class="{ check: joinGroup == i.sid }"
						@click="choseStudy(i.sid)"
						:title="power == 1 ? '点击选择加入小组的成员' : ''"
						v-for="(i, index) in studentList"
						:key="index"
					>
						<div class="name">{{ i.uname }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { $getGroupList, $joinGroup } from "../../services/course";

export default {
	name: "studyGroup",
	data() {
		return {
			groupList: [],
			studentList: [],
			power: 2,
			joinGroup: -1,
		};
	},
	components: {},

	computed: {
		userInfo() {
			return this.$store.getters.useInfo;
		},
	},

	created() {
		this.courseId = this.$route.query.id;
		this.getGroupList();
	},

	methods: {
		async getGroupList() {
			const { data } = await $getGroupList({
				kcid: this.courseId,
			});
			this.power = data.zutype;
			this.groupList = data.zulist;
			this.studentList = data.userlist.userlist ?? [];
		},
		choseStudy(id) {
			if (this.power == 2) return;
			if (this.joinGroup == id) {
				this.joinGroup = -1;
			} else {
				this.joinGroup = id;
			}
			// const index = this.joinGroup.indexOf(id);
			// if (index == -1) {
			// 	this.joinGroup.push(id);
			// } else {
			// 	this.joinGroup.splice(index, 1);
			// }
		},
		handleJoinGroup() {
			if (this.power == 2) {
				this.$message.error("您还没有相关权限");
				return;
			}
			if (this.joinGroup == -1) {
				this.$message.warning("您还没有选择加入小组的成员");
				return;
			}
			console.log(111);
			this.$confirm("确定要将选中的成员加入小组吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.submitJoinGroup();
				})
				.catch(() => {});
		},
		async submitJoinGroup() {
			try {
				const { data } = await $joinGroup({
					kcid: this.courseId,
					lrsid: this.joinGroup,
				});
				this.joinGroup = -1;
				this.getGroupList();
				this.$message.success("拉入小组成功");
			} catch (error) {
				this.$message.error("拉入小组失败");
			}
		},
	},
};
</script>

<style scoped lang="less">
.com-studyGroup {
	padding-top: 68px;
	.com-content {
		width: 1200px;
		min-height: 500px;
		margin: 0 auto;
		.com-title {
			height: 87px;
			line-height: 67px;
			padding-top: 20px;
			border-bottom: 1px solid #eeefef;
			font-size: 18px;
			font-weight: bold;
			color: #1c1f21;
		}
		.group-list-wrap {
			padding: 45px 0;
			margin-bottom: 25px;
			border-bottom: 1px solid #eeefef;
			.group-title {
				.title {
					font-size: 15px;
					font-weight: bold;
					color: #1c1f21;
				}
				.count {
					font-size: 16px;
					font-weight: 400;
					color: #545c63;
					margin-left: 32px;
					.now-count {
						color: #f54747;
					}
				}
			}
			.group-list {
				padding-top: 10px;
				.item {
					width: 160px;
					height: 60px;
					background: #f2f2f2;
					border: 1px solid #f5f7fa;
					border-radius: 8px;
					margin-top: 20px;
					position: relative;
					font-size: 16px;
					color: #545c63;
					margin-right: 43px;
					&.hover {
						cursor: pointer;
					}
					&:nth-child(6n) {
						margin-right: 0;
					}
					.tag {
						width: 54px;
						height: 26px;
						position: absolute;
						left: -4px;
						top: -7px;
						font-size: 14px;
						line-height: 26px;
						text-align: center;
						font-weight: bold;
						color: #ffffff;
						background-image: url("../../assets/image/tag-group-bg.png");
						background-repeat: no-repeat;
						background-size: 100%;
					}
					&.check {
						border: 2px solid #15abee;
						color: #15abee;
						background-color: #fff;
					}
					&.join {
						cursor: pointer;
						color: #ffffff;
						background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
					}
				}
			}
		}
	}
}
</style>
