<!--  -->
<template>
	<div class="com-curriculum">
		<div class="com-content flex-jb">
			<div class="catalogue-container">
				<div class="chapter" v-for="(item, index) in classList" :key="item.id">
					<div class="chapter-title">第{{ index + 1 }}章 {{ item.zjname }}</div>
					<div class="sections flex-ac" :class="{ prohibit: i.ksshowtype == 3 }" v-for="i in item.kslist" :key="i.id">
						<div class="section-title flex-ac" @click="toStudyPage(i, item.zjname)" :class="{ status0: i.sectionStatus == 0 }">
							<div class="label">{{ i.label }}</div>
							<div class="title">{{ i.ksname }}</div>
						</div>
						<!-- study-status0为课程未到学习时间，需显示可开始学习时间的class样式； study-status1为已学习全部的class样式； study-status2为显示学习进度的class样式 -->
						<!-- <div class="study-status0" v-if="i.usksxxtype == 3 && i.kstime && hasLogin">{{ i.kstime }}</div>
						<div class="study-status1" v-else-if="i.usksxxtype == 2 && hasLogin">已完成</div> -->
						<div class="study-status2">已学至{{ i.usksxxtype }}</div>
						<div @click="toStudyPage(i, item.zjname)">
							<!-- <div class="btn btn-status1" v-if="!hasLogin">学习</div> -->
							<div class="btn btn-status2">{{ i.anniu }}</div>
							<!-- <div class="btn btn-status0" v-else-if="i.usksxxtype == 3">学习</div>
							<div class="btn btn-status1" v-else-if="i.usksxxtype == 2 && i.tmtype != 3">去做题</div>
							<div class="btn btn-status2" v-else>{{ i.usksxxtype == 1 ? "学习" : "继续学习" }}</div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="course-introduction">
				<div class="nav-title">课程介绍</div>
				<div class="introduction">
					<div class="title">学习时间</div>
					<div class="content">{{ classInfo.xxsj }}</div>
					<div class="title">课程简介</div>
					<div class="content" v-html="classInfo.kcjj"></div>
					<div class="title">学习目标</div>
					<div class="content" v-html="classInfo.xxmb"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "curriculum",
	props: {
		classInfo: {
			type: Object,
			default() {
				return {};
			},
		},
		classList: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			catalogueList: [],
		};
	},
	components: {},

	computed: {
		hasLogin() {
			if (this.$store.getters.useInfo && this.$store.getters.useInfo._token) {
				return true;
			} else {
				localStorage.clear();
				return false;
			}
		},
	},

	created() {},

	methods: {
		toStudyPage(item, title) {
			const kcid = this.$route.query.id;
			if (!this.hasLogin || item.ksshowtype == 1) {
				localStorage.clear();
				this.$store.commit("UPDATEDSTATE", { key: "useInfo", value: {} });
				this.$message.error('您未登录或登录已失效，请重新登录')
				this.$router.push({ path: "/login" });
				return;
			} else if (item.ksshowtype == 3) {
				return;
			} else if (item.ksshowtype == 4) {
				this.$message.warning("您还未报名,请报名后再试");
				return;
			}

			this.$router.push({
				path: "/study",
				query: {
					kcid,
					id: item.id,
					title,
				},
			});
		},
	},
};
</script>

<style scoped lang="less">
.com-curriculum {
	padding-top: 68px;
	.com-content {
		width: 1200px;
		margin: 0 auto;
		.catalogue-container {
			width: 860px;
			.chapter {
				width: 860px;
				background: #ffffff;
				border-radius: 8px;
				box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.2);
				padding: 14px 44px 16px 36px;
				margin-bottom: 20px;
				.chapter-title {
					height: 74px;
					line-height: 74px;
					font-size: 18px;
					font-weight: bold;
					color: #1c1f21;
					border-bottom: 1px solid #eeefef;
					margin-bottom: 18px;
				}
				.sections {
					// margin-bottom: 10px;
					padding: 25px 10px;
					height: 38px;
					border-bottom: 1px solid rgba(0, 0, 0, 0.05);
					// &:nth-of-type(2n) {
					// 	background-color: rgba(0,0,0,.03);
					// }
					&.prohibit {
						opacity: 0.3;
					}
					.section-title {
						flex: 1;
						font-size: 16px;
						color: #1c1f21;
						cursor: pointer;
						.label {
							min-width: 60px;
							height: 26px;
							line-height: 26px;
							text-align: center;
							padding: 0 10px;
							background: linear-gradient(72deg, #ffb031 0%, #ffd500 100%);
							border-radius: 12px 4px 12px 4px;
							color: #fff;
							margin-right: 10px;
						}
					}
					.study-status0 {
						min-width: 200px;
						height: 38px;
						font-size: 15px;
						line-height: 36px;
						color: #ff4f5e;
						background: #ffffff;
						border: 2px solid #ff4f5e;
						box-shadow: 0px 3px 8px 0px rgba(224, 224, 224, 0.5);
						border-radius: 8px;
						text-align: center;
					}
					.study-status1 {
						width: 88px;
						height: 30px;
						font-size: 14px;
						line-height: 30px;
						text-align: center;
						color: #545c63;
						background: #f3f5f6;
						border-radius: 15px;
					}
					.study-status2 {
						font-size: 14px;
						color: #3382ff;
					}
					.btn {
						min-width: 110px;
						height: 30px;
						padding: 0 10px;
						line-height: 28px;
						text-align: center;
						font-size: 14px;
						margin-left: 21px;
						border-radius: 15px;
						user-select: none;
						cursor: pointer;
					}
					.btn-status1 {
						border: 1px solid #16abee;
						color: #16abee;
					}
					.btn-status2 {
						line-height: 30px;
						color: #ffffff;
						background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
					}
					.btn-status0 {
						cursor: not-allowed;
						line-height: 30px;
						color: #ffffff;
						opacity: 0.2;
						background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
					}
				}
			}
		}
		.course-introduction {
			width: 300px;
			.nav-title {
				font-size: 18px;
				font-weight: bold;
				color: #1c1f21;
			}
			.introduction {
				margin-top: 25px;
				width: 300px;
				padding: 45px 25px 20px 27px;
				box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.2);
				.title {
					font-size: 15px;
					font-weight: bold;
					color: #1c1f21;
					margin-bottom: 20px;
				}
				.content {
					font-size: 15px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #545c63;
					margin-bottom: 45px;
				}
			}
		}
	}
}
</style>
